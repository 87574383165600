// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent}
.owl-theme .owl-nav{margin-top:10px}
.owl-theme .owl-nav [class*=owl-]{color:#FFF;font-size:14px;margin:5px;padding:4px 7px;background:#D6D6D6;display:inline-block;cursor:pointer;border-radius:3px;position: absolute;}
.owl-theme .owl-nav [class*=owl-]:hover{background:#869791;color:#FFF;text-decoration:none}
.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}
.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}
.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}
.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}
.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}`, "",{"version":3,"sources":["webpack://./src/css/owl.theme.default.min.css"],"names":[],"mappings":"AAAA;;;;EAIE;AACF;oBACoB,iBAAiB,CAAC,uCAAuC;AAC7E,oBAAoB,eAAe;AACnC,kCAAkC,UAAU,CAAC,cAAc,CAAC,UAAU,CAAC,eAAe,CAAC,kBAAkB,CAAC,oBAAoB,CAAC,cAAc,CAAC,iBAAiB,CAAC,kBAAkB,CAAC;AACnL,wCAAwC,kBAAkB,CAAC,UAAU,CAAC,oBAAoB;AAC1F,8BAA8B,UAAU,CAAC,cAAc;AACvD,uCAAuC,eAAe;AACtD,8BAA8B,oBAAoB,CAAC,MAAM;AACzD,mCAAmC,UAAU,CAAC,WAAW,CAAC,cAAc,CAAC,kBAAkB,CAAC,aAAa,CAAC,mCAAmC,CAAC,2BAA2B,CAAC,kBAAkB;AAC5L,mFAAmF,kBAAkB","sourcesContent":["/**\n * Owl Carousel v2.2.1\n * Copyright 2013-2017 David Deutsch\n * Licensed under  ()\n */\n.owl-theme .owl-dots,\n.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent}\n.owl-theme .owl-nav{margin-top:10px}\n.owl-theme .owl-nav [class*=owl-]{color:#FFF;font-size:14px;margin:5px;padding:4px 7px;background:#D6D6D6;display:inline-block;cursor:pointer;border-radius:3px;position: absolute;}\n.owl-theme .owl-nav [class*=owl-]:hover{background:#869791;color:#FFF;text-decoration:none}\n.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}\n.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}\n.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}\n.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}\n.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
